import React from 'react'
import * as S from './style'

// Sell

export default () => {
  return (
    <S.Ul>
      {/* <li>
        <S.BookWa
          href='https://chat.whatsapp.com/EjkCh65xkKO2kxd1nWPYqF'
          target='_blank'
        >
          <S.WaLogo />
          <span> Entrar para a list VIP </span>
        </S.BookWa>
      </li> */}
    </S.Ul>
  )
}
